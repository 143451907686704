import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import ResumeItem from "../components/ResumeItem"

const AboutPage = ({ data }) => {
  const resume = data.dataJson

  return (
    <>
      <Layout>
        <h1>Victor Bodell</h1>
        <p>
          I'm Victor Bodell, a &#x2601; & &#x1f4bb; professional and parttime
          freelance musician. I'm happy to welcome you to my home on the www!
          You can think of this page as my elevator pitch &#x1F609;
        </p>
        <p>
          My passions are travel, language, &#x1f3b5; & tech, which likely
          shines through my posts on this site. I strive to be a part of teams
          that shape technology that fascinates, transforms and improves people,
          in environments that value creativity, competence and quality.
          Colleagues and friends speak of me as a good listener, humble,
          ambitious, reliable & a good leader.
        </p>
        <p>
          I've studied Data Science at KTH and worked with &#x1f4f1; and
          frontend development. Currently I'm a product owner in the Cloud
          Transformation at <a href="https://seb.se/">SEB</a>. You'll find some
          of my current and previous work on this site.
        </p>
        <a className="btn-link" href="resume.pdf">
          Download Resume
        </a>

        <h2>Work</h2>
        {resume.work.map((w, index) => (
          <ResumeItem key={`work${index}`} content={w} itemType="work" />
        ))}
        <h2>Education</h2>
        {resume.education.map((e, index) => (
          <ResumeItem
            key={`education${index}`}
            content={e}
            itemType="education"
          />
        ))}
      </Layout>
    </>
  )
}

export default AboutPage

export const query = graphql`
  query {
    dataJson {
      education {
        icon {
          childImageSharp {
            gatsbyImageData
          }
        }
        alt
        degree
        description
        graduated
        school
      }
      work {
        alt
        company
        description
        icon {
          childImageSharp {
            gatsbyImageData
          }
        }
        title
        years
      }
    }
  }
`
