import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"


// TODO: DRY
const WorkItem = ({ work }) => {
    return (
        <>
        <h3>{work.company}</h3>
            <p className="info">
                <em>{work.title}</em> <span>&bull;</span> {work.years}
            </p>
            <p className="description">{work.description}</p>
        </>
    )
}

const EducationItem = ({ education }) => {
    return (
        <>
                <h4>{education.school}</h4>
                <p className="info">
                    <em>{education.degree}</em> <span>&bull;</span> {education.graduated}
                </p>
                <p className="description">{education.description}</p>
           </>
    )
}

const ResumeItem = ({ content, itemType }) => {
    const image = getImage(content.icon)
      return (
          <ResumeStyle>
          <div className="resumeItem">
        <GatsbyImage className="resumeIcon" image={image} alt={content.alt}/>
          <div className="resumeText">
          {(itemType === "education" ? 
      <EducationItem education={content} /> :
      <WorkItem work={content} />
        )}
          </div>
            </div>
          </ResumeStyle>
      )
}

// TODO: Proptypes for education/work
ResumeItem.propsTypes = {
  content: PropTypes.any,
  itemType: PropTypes.string
}

const ResumeStyle = styled.div`
  .resumeItem {
    display: grid;
    @media (min-width: 600px) {
        column-gap: 2rem;
        grid-template-columns: 1fr 5fr;
    }
  }

  .description {
    color: #838C95;
  }

  .resumeText {
    align-self: center;
  }

  .resumeIcon {
    align-self: center;
    border-radius: 5px;
    justify-self: center;
    height: 170px;
    width: 170px;
    @media (min-width: 600px) {
        height: 130px;
        width: 130px;
    }
  }

`

export default ResumeItem
